<template>
	<div class="pt-5 text-left">
		<div class="pb-2 text-lg font-bold text-black">
			Join our newsletter
		</div>
		<p class="pb-4 text-sm font-normal">
			Stay up to date with Wheres Weed, Exclusive Offers, Cannabis news, Cannabis products, Information, and so much more.
		</p>
		<button
			class="w-full px-3 font-bold text-white uppercase transition-all duration-200 ease-in-out bg-green-500 rounded-md xl:hover:bg-green-400 h-11 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-green-500 focus:ring-opacity-80"
			@click="showModal(NEWSLETTER_SIGNUP_MODAL)"
		>
			SIGN UP
		</button>
		<NewsLetterSignupModal />
	</div>
</template>

<script defer>
import { mapMutations } from 'vuex'

import { NEWSLETTER_SIGNUP_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		NewsLetterSignupModal: () => import('@/components/UI/modals/NewsletterSignupModal.vue')
	},
	data() {
		return {
			NEWSLETTER_SIGNUP_MODAL
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ])
	}
}
</script>
